<template>
  <div class="w-full mt-4 md:mt-0 flex flex-col-reverse md:flex-row">
    <div class="w-full pt-0 md:pt-12 mb-2 flex justify-between items-center">
      <HeaderTab v-if="tab" />
      <HeaderTabGlobal v-else-if="tab2" />
      <div v-else class="w-full flex items-center font-proximaLight">
        <div v-if="titlemenu4">
          <button class="menuButton1">
            <div class="flex justify-center items-center">
              <a v-if="to1" @click="goTo(to1)">
                <h3 class="text-xs md:text-sm">{{ titlemenu1 }}</h3>
              </a>
              <h3 v-else class="text-xs md:text-sm">{{ titlemenu1 }}</h3>
              <ArrowNext class="ml-3 mr-3" />
              <a v-if="to2" @click="goTo(to2)">
                <h3 class="text-xs md:text-sm">{{ titlemenu2 }}</h3>
              </a>
              <h3 v-else class="text-xs md:text-sm">{{ titlemenu2 }}</h3>
              <ArrowNext class="ml-3 mr-3" />
              <h3 class="text-xs md:text-sm">{{ titlemenu3 }}</h3>
              <ArrowNext class="ml-3" />
            </div>
          </button>
          <button class="menuButton2">
            <h3 class="text-xs md:text-sm text-white">{{ titlemenu4 }}</h3>
          </button>
        </div>
        <div v-else>
          <div v-if="titlemenu3">
            <button class="menuButton1">
              <div class="flex justify-center items-center">
                <a v-if="to1" @click="goTo(to1)">
                  <h3 class="text-xs md:text-sm">{{ titlemenu1 }}</h3>
                </a>
                <h3 v-else class="text-xs md:text-sm">{{ titlemenu1 }}</h3>
                <ArrowNext class="ml-3 mr-3" />
                <a v-if="to2" @click="goTo(to2)">
                  <h3 class="text-xs md:text-sm">{{ titlemenu2 }}</h3>
                </a>
                <h3 v-else class="text-xs md:text-sm">{{ titlemenu2 }}</h3>
                <ArrowNext class="ml-3" />
              </div>
            </button>
            <button class="menuButton2">
              <h3 class="text-xs md:text-sm text-white">{{ titlemenu3 }}</h3>
            </button>
          </div>
          <div v-else>
            <button v-if="titlemenu1" class="menuButton1">
              <div class="flex justify-center items-center">
                <a v-if="to1" @click="goTo(to1)">
                  <h3 class="text-xs md:text-sm">
                    {{ titlemenu1 }}
                  </h3>
                </a>
                <h3 v-else class="text-xs md:text-sm">{{ titlemenu1 }}</h3>
                <ArrowNext class="ml-3" />
              </div>
            </button>
            <button v-if="titlemenu2" class="menuButton2">
              <a v-if="to2" @click="goTo(to2)">
                <h3 class="text-xs md:text-sm text-white">{{ titlemenu2 }}</h3>
              </a>
              <h3 v-else class="text-xs md:text-sm text-white">
                {{ titlemenu2 }}
              </h3>
            </button>
          </div>
        </div>
      </div>
      <HeaderSearch v-if="showHeaderSearch" />
      <div
        class="text-sm text-left md:text-right font-proximaLight flex justify-center items-center cursor-pointer"
      >
        <!-- <span class="text-xs md:text-sm">←</span>
        <span class="ml-1">{{ $t("global_back") }}</span> -->
      </div>
      <template v-if="authenticated && !publicScreen">
        <div
          class="text-sm text-right font-proximaLight flex justify-center items-center"
        >
          <!-- to show navigation -->
          <NavMenu :user="user" :role="role" :class="profilepicture" />
          <!-- to show navigation -->
        </div>
      </template>
    </div>
    <div
      class="md:h-2/5 lg:h-2/3 md:hidden flex justify-center items-center"
      v-if="showLogo"
    >
      <span v-if="showTooltip" class="font-proximaLight tooltip"
        >Click me!</span
      >
      <img
        v-if="routeName !== 'SharingSetting'"
        class="w-6/12 md:w-10/12 lg:w-9/12 mb-10"
        :class="{ 'box bounce-7': showTooltip }"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
        @click="logoTouch"
        @mousedown="start"
        @mouseleave="stop"
        @mouseup="stop"
        @touchstart="start"
        @touchend="stop"
        @touchcancel="stop"
      />
    </div>
    <!-- AI assistant modals -->
    <ModalsConfirm :show="watchCount">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaMedium cursor-pointer"
          @click="count = 0"
        >
          ✖
        </div>
        <img
          class="h-44 m-auto"
          src="@/assets/images/panicbutton-icon.png"
          alt="panicbutton-icon"
        />
        <h1 class="text-2xl">AI Assistant</h1>
      </div>
      <div slot="actionText">
        <SubmitButton
          class=""
          title="Emergency Vet"
          background="bg-orange"
          paddingX="px-8"
          paddingY="py-10"
          marginT="mt-4"
          fontSize="text-base"
          width="w-full"
          @click.native="openEmergencyPet"
        />
        <!-- <SubmitButton class="" title="Pet Scanner" background="bg-primary" paddingX="px-8" paddingY="py-10" marginT="mt-4" fontSize="text-base" width="w-full" /> -->
        <SubmitButton
          class=""
          title="Community Search"
          background="bg-orange"
          paddingX="px-8"
          paddingY="py-10"
          marginT="mt-4"
          fontSize="text-base"
          width="w-full"
          @click.native="openComSearch"
        />
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- AI assistant modals end -->
    <!-- community search modals -->
    <ModalsConfirm :show="communitySearchModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaMedium cursor-pointer"
          @click="communitySearchModals = false"
        >
          ✖
        </div>
        <img
          class="m-auto"
          src="@/assets/images/Distressed1.png"
          alt="Distressed1"
        />
        <h1 class="text-2xl">Community Search</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          You are about to trigger an automated process which will lead to the
          following:
        </h5>
        <ul class="mt-4 list-disc text-left font-proximaLight text-sm">
          <li>
            App users within a 1 km radius of your location will receive a
            notification
          </li>
          <li>
            Your current map coordinates will be shared with users who react
          </li>
          <li>Your pet’s public profile will be shared</li>
          <li>Your pet’s status will change to “lost”</li>
          <li>
            The notification will be deactivated after 1 hour or once the pet is
            found
          </li>
        </ul>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeAddOwnerModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-red"
        paddingX="px-8"
        width="w-full"
        @click.native="openIdentifyModals"
      />
    </ModalsConfirm>
    <!-- community search modals -->
    <!-- identify lost pets modals -->
    <ModalsConfirm :show="identifyLostPets" @close="identifyLostPets = false">
      <div slot="actionQuestion">
        <div class="w-max m-auto rounded-full p-6 bg-primary-20">
          <MedicalHistIcon width="82px" height="82px" />
        </div>
        <h1 class="mt-4 text-2xl">Identify Lost Pet(s)</h1>
      </div>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2">Select at least one pet </span>
        <div class="h-52 w-full md:w-9/12 overflow-scroll overflow-x-hidden">
          <div
            class="my-2 flex items-center justify-between"
            v-for="(contact, i) in contactsComp"
            :key="i"
          >
            <div class="flex items-center">
              <img
                class="rounded-full w-6 h-6"
                :src="contact.profile_picture"
                alt="profilepic-icon"
              />
              <span class="ml-2">{{ contact.callName }}</span>
            </div>
            <RadioOnoffBorder
              :checked="contact.checked"
              :checkAll="checkAll"
              :val="contact"
              @inputVal="inputVal"
            />
          </div>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="identifyLostPets = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitLostPet"
      />
    </ModalsConfirm>
    <!-- identify lost pets modals end -->
    <!-- success modals -->
    <ModalsConfirm :show="successModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaMedium cursor-pointer"
          @click="successModals = false"
        >
          ✖
        </div>
        <img
          class="m-auto h-28"
          src="@/assets/images/success-icon.png"
          alt="success-icon"
        />
        <h1 class="text-xl">Lost Pet Location Shared Successfully</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          If any users find and scan your pet, you will receive a notification
          with the coordinates of their location.
        </h5>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- success modals end -->
    <!-- success modals -->
    <ModalsConfirm :show="attentionModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto h-28"
          src="@/assets/images/attention-icon.png"
          alt="attention-icon"
        />
        <h1 class="text-xl">Rocky Was Scanned 300m Away</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          We hope that you will soon be reunited with your pet! Users can
          contact you through your lost pet’s profile.
        </h5>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Dismiss"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="attentionModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Open Map"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
      />
    </ModalsConfirm>
    <!-- success modals end -->

    <PetLostModals ref="myChild" />
    <InjuredPetModals ref="injuredPet" :conntacts="this.contacts" />
  </div>
</template>

<script>
import NavMenu from "../navigationmenu/NavMenu.vue";
import ArrowNext from "../icons/ArrowNext.vue";
import HeaderTab from "./HeaderTab.vue";
import HeaderTabGlobal from "./HeaderTabGlobal.vue";
import HeaderSearch from "./HeaderSearch.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import MedicalHistIcon from "../icons/MedicalhistIcon.vue";
import PetLostModals from "./nonownermodals/PetLostModals.vue";
import InjuredPetModals from "./injuredpet/InjuredPetModals";
import { reportPetAsLost } from "../../services/panicButtonService";
import { petOfUser, petPicture } from "../../methods/petMethods";
import Parse from "parse";
//import posthog from "posthog-js";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    NavMenu,
    ArrowNext,
    HeaderTab,
    HeaderTabGlobal,
    HeaderSearch,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    RadioOnoffBorder,
    MedicalHistIcon,
    PetLostModals,
    InjuredPetModals,
  },
  props: {
    step: {
      type: String,
      default: "step",
    },
    title: {
      type: String,
      default: "title",
    },
    back: {
      type: String,
      default: "back",
    },
    titlemenu1: {
      type: String,
    },
    titlemenu2: {
      type: String,
    },
    titlemenu3: {
      type: String,
    },
    titlemenu4: {
      type: String,
    },
    to1: {
      type: String,
      default: "",
    },
    to2: {
      type: String,
      default: "",
    },
    profilepicture: {
      type: String,
      default: "block",
    },
  },
  data() {
    return {
      showLogo: true,
      publicScreen: false,
      tab: false,
      tab2: false,
      showHeaderSearch: false,
      showTooltip: false,
      showModals: false,
      interval: false,
      count: 0,
      communitySearchModals: false,
      identifyLostPets: false,
      successModals: false,
      checkAll: false,
      attentionModals: false,
      member: false,
      coordinates: {
        lng: 3.05997,
        lat: 36.7762,
      },
      contacts: [
        /*   {
          id: "1",
          name: "Pet 1",
          checked: false,
        }, */
      ],
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      petRecords: "petRecord/getPetRecords",
      user: "auth/user",
      role: "auth/role",
    }),

    routeName() {
      return this.$route.name;
    },

    watchCount() {
      if (this.count >= 30) {
        return true;
      } else {
        return false;
      }
    },
    contactsComp() {
      return this.petRecords
        ? this.petRecords.filter((item) => item.type == 2 || item.type == 1)
        : [];
    },
  },

  async created() {
    const routeName = this.$route.name;

    if (
      routeName == "DogBreedAccount" ||
      routeName == "ShelterAccount" ||
      routeName == "Account"
    ) {
      this.showLogo = false;
    }

    if (routeName == "PetDeceased") {
      this.showHeaderSearch = true;
    }

    if (routeName == "PublicProfileNonContact") {
      this.publicScreen = true;
    }
    /* if (!localStorage.getItem("role") || !localStorage.getItem("email")) {
      this.$router.push({ name: "Join" });
    } */

    this.tabHeader();

    //----------------------coordinates
    //TODO: localisation should required
    this.$getLocation({})
      .then((coordinates) => {
        this.coordinates = coordinates;
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
      });

    this.getPetRecords(localStorage.getItem("email"));

    console.log("bbbbbbb", this.contacts);
  },

  methods: {
    ...mapActions({
      getPetRecords: "petRecord/getPetRecords",
    }),

    goTo(page) {
      this.$router.push({ path: page });
    },

    petPicture(pet) {
      return petPicture(pet);
    },

    inputVal(contact, checked) {
      contact.checked = checked;

      console.log(contact, checked);

      /*  const conts = [...this.contacts];
      const index = conts.indexOf(contact);
      conts[index] = { ...contact }; */
      this.contacts.push(contact);
      console.log(this.contacts);
    },
    tabHeader() {
      const routeName = this.$route.name;

      if (routeName === "PetOverview" || routeName === "PetOverview1") {
        this.tab = true;
      } else if (routeName === "Contacts") {
        this.tab2 = true;
      }
    },
    logoTouch() {
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 300);
    },
    start() {
      if (!this.interval) {
        this.interval = setInterval(() => this.count++, 30);
      }
    },
    stop() {
      clearInterval(this.interval);
      this.interval = false;
    },

    openEmergencyPet() {
      this.count = 0;
      this.$refs.injuredPet.checkAreaModals(true);
    },
    openComSearch() {
      this.communitySearchModals = true;
      this.count = 0;
      /*  if (this.member == true) {
        this.communitySearchModals = true;
      } else {
        this.$refs.myChild.checkAreaModals(true);
      } */
    },
    openIdentifyModals() {
      this.communitySearchModals = false;

      this.identifyLostPets = true;
    },
    async submitLostPet() {
      try {
        const vals = this.contacts.filter((p) => p.CountryofOrigin == 2);
        console.log(
          this.contacts,
          this.contacts.filter((p) => p.CountryofOrigin == 2)
        );
        console.log(vals);

        if (vals.length !== 0) {
          const pets = [];
          vals.map((v) => pets.push(v.petID));

          const data = {
            pets: pets,
            userEmailAddress: localStorage.getItem("email"),
            location_long: this.coordinates.lng, //3.05997,
            location_lat: this.coordinates.lat, //36.7762,
          };
          console.log(data);

          const { data: result } = await reportPetAsLost(data);
          console.log(result);

          //add Posthog

          console.log("--------- event posthog -------");
          /*   result.users_to_notify.forEach(async (userN) => {
            result.pets.forEach(async (pet) => {
              //posthog.capture("my event", { property: "selma" });
              //const distinctId = userN + pet;
              const idPerson = result.id + userN.email + pet;
              console.log(result.id);
              console.log("****", idPerson);
              posthog.identify(idPerson);
              posthog.capture("EventLostPet", {
                location_lat: result.location_lat,
                location_long: result.location_long,
                user_to_notify: userN.email,
                pet: pet,
                notification_status: result.notification_status,
                id_pet: result.id,
              });
              console.log(result.id);
            });
          }); */

          console.log("--------- end event posthog -------");

          //posthog end ------------

          // add to Parse
          /*   result.users_to_notify.forEach(async (userN) => {
            result.pets.forEach(async (pet) => {
              const ReportLostPet = Parse.Object.extend("reportLostPet");
              const reportLostPet = new ReportLostPet();
              console.log(
                result.id,
                result.location_lat,
                result.location_long,
                userN.email,
                pet,
                result.notification_status
              );

              reportLostPet.set("location_lat", result.location_lat);
              reportLostPet.set("location_long", result.location_long);
              reportLostPet.set("user_to_notify", userN.email);
              reportLostPet.set("pet", pet);
              reportLostPet.set(
                "notification_status",
                result.notification_status
              );
              reportLostPet.set("id_pet", result.id);

              console.log("reportLostPet", reportLostPet);

              reportLostPet.save().then(
                (reportLostPet) => {
                  // Execute any logic that should take place after the object is saved.
                  alert(
                    "New object created with objectId: " + reportLostPet.id
                  );
                },
                (error) => {
                  // Execute any logic that should take place if the save fails.
                  // error is a Parse.Error with an error code and message.
                  alert(
                    "Failed to create new object, with error code: " +
                      error.message
                  );
                }
              );
            });
          }); */
          //----- End add to Parse -----
          this.identifyLostPets = false;
          this.successModals = true;
        } else {
          console.log("choise a pet");
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  user-select: none !important;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.menuButton1 {
  //width: 192px;
  padding-left: 20px;
  padding-right: 20px;
  height: 43px;
  background: #ffffff;
  border-radius: 40px;
  z-index: 100;
}

.menuButton2 {
  position: relative;
  margin-left: -30px;
  padding-left: 40px;
  padding-right: 30px;
  height: 43px;
  background: linear-gradient(270deg, #09a89d 1.41%, #0ac0cb 23.78%);
  border-radius: 40px;
  z-index: -1;
}

.tooltip {
  position: absolute;
  top: 5px;
  font-size: 14px;
  background: #f8961f;
  color: #fafafa;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  animation: fadein 1.7s;
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #f8961f;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.box {
  animation-duration: 1.2s;
  animation-iteration-count: inherit;
}

.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-50px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-7px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
